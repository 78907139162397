<template>
    <vineyards-module-component/>
</template>

<script>
    import VineyardsModuleComponent from "@/components/admin/modules/VineyardsModuleComponent";

    export default {
        name: "VineyardsModule",
        title: "Gestión de Viñedos | Turismo BC",
        components: { VineyardsModuleComponent }
    }
</script>

<style scoped>

</style>